<template>
  <div v-show="!isHidden" style="width: 100%; height: 100%">
    <div>
      <el-button
        v-show="this.isEditor()"
        :type="isSaveFilter ? 'info' : 'warning'"
        size="small"
        style="position: absolute; bottom: 4px; right: 290px;z-index: 2;"
        @click="saveFilterModelAndStateTable"
      >
        <!-- {{ $t('main.button.save_filter') }}  ошибка при открывании таблицы в окне О-о-->
        {{$locale.main.button.save_filter}}
      </el-button>
    </div>

    <div class="list_toolbar" v-show="isShowBtnRefresh" :style="styleContainerBtnRefresh">
      <el-button
        title="Обновить"
        type="text"
        icon="el-icon-refresh-right"
        class="size"
        @click="refreshGrid"
        :style="styleBtnRefresh"
      ></el-button>
    </div>

    <grid
      ref="grid"
      :key="key"
      :columns="columns"
      :pagination="pagination"
      :data-source-service="dataSourceService"
      :multi-selection="multiSelection"
      :check-box-selection="checkBoxSelection"
      :is-pivot-mode="isPivotMode"
      :filterModel="filterModel"
      :sideBar="sideBar"
      :closeToolPanel="closeToolPanel"
      :floatingFilter="floatingFilter"
      :groupUseEntireRow="groupUseEntireRow"
      :readonly="_isReadonly"
      :disabled-column-header="disabledColumnHeader"
      :hide-header="hideHeader"
      :wrap-header="wrapHeader"
      :theme="theme"
      :CSS="CSS"
      :CSSClasses="CSSClasses"
      :rowClassRulesProps="rowClassRules"
      :page-size="pageSize"
      :cache-block-size="cacheBlockSize"
      :rowHeight="rowHeight"
      :suppressGroupRowsSticky="suppressGroupRowsSticky"
      :context-menu="getContextMenu()"
      :primaryField="primaryField"
      :isAutoRefresh="isAutoRefresh"
      @grid-ready="isGridReady = true"
      @loaded="setRowCount"
    ></grid>

    <slot></slot>
  </div>
</template>

<script type="ts">
import Vue from 'vue'
import Grid from '@/core/infrastructure/components/Grid/index.vue'
import mixin from '@/components/InterfaceEditor/components/mixins'
import VisibleMixin from '@/components/InterfaceEditor/components/visible_properties_mixin'
import { DataWarehouseSource } from '@/components/InterfaceEditor/components/basic/AnalyticTable/DataSources/DataWarehouseSource'
import FilterBuilder, { EComponentTypes } from '../../utils'
import { deepCompare } from '@/helpers'

export default Vue.extend({
  name: 'AnalyticTable',

  components: { Grid },

  mixins: [mixin, VisibleMixin],

  inject: {
    isEditor: {
      default: () => false
    },
    getComponents: {
      default: () => () => []
    }
  },

  props: {
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },

    editorAlias: {
      type: String,
      description: 'alias'
    },

    source: {
      type: Object,
      editor: 'AnalyticTableSource',
      default: () => {
        return {
          type: null,
          entityId: null,
          queryParameters: []
        }
      }
    },

    columns: {
      description: 'columns',
      type: Array,
      default: () => [],
      editor: 'TableColumns',
      options: {
        sourceType: 'external'
      }
    },

    hideExport: {
      type: Boolean,
      description: 'hide_export',
      default: false
    },

    checkBoxSelection: {
      type: Boolean,
      description: 'check_box_selection',
      default: false
    },

    multiSelection: {
      type: Boolean,
      description: 'multy_selection',
      default: false
    },

    pagination: {
      type: Boolean,
      description: 'pagination',
      default: true
    },

    sideBar: {
      type: Boolean,
      description: 'side_bar',
      default: false
    },

    floatingFilter: {
      type: Boolean,
      description: 'floating_filter',
      default: false
    },

    groupUseEntireRow: {
      type: Boolean,
      description: 'group_use_entire_row',
      default: false
    },

    closeToolPanel: {
      type: Boolean,
      description: 'close_tool_panel',
      default: true
    },

    theme: {
      editor: 'Select',
      description: 'theme_table',
      default: 'ag-theme-alpine',
      options: {
        multiple: false,
        options: [
          { name: 'Цвет: светлый; Высота: с отступами', id: 'ag-theme-alpine' },
          { name: 'Цвет: светлый; Высота: минимальная', id: 'ag-theme-balham' },
          { name: 'Цвет: темный; Высота: с отступами', id: 'ag-theme-alpine-dark' },
          { name: 'Цвет: темный; Высота: минимальная', id: 'ag-theme-balham-dark' }
        ]
      }
    },

    disabledColumnHeader: {
      type: Boolean,
      default: false,
      description: 'disabled_column_header'
    },

    hideHeader: {
      type: Boolean,
      default: false,
      description: 'hide_header'
    },

    suppressGroupRowsSticky: {
      type: Boolean,
      default: true,
      description: 'suppress_group_rows_sticky'
    },

    wrapHeader: {
      type: Boolean,
      default: false,
      description: 'wrap_header'
    },

    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },

    isShowBtnRefresh: {
      type: Boolean,
      description: 'show_refresh_btn',
      default: false
    },

    rowClassRules: {
      type: Array,
      default: function () {
        return []
      },
      editor: 'RowClassRules'
    },
    autoRefresh: {
      type: [Object, Array],
      editor: 'AutoRefresh',
      default: function () {
        return {}
      }
    },
    pageSize: {
      type: Number,
      description: 'page_size',
      default: 100,
      options: {
        tooltip: {
          show: true,
          content: `По умолчанию 100`
        }
      }
    },
    cacheBlockSize: {
      type: Number,
      description: 'cache_block_size',
      default: 50,
      options: {
        tooltip: {
          show: true,
          content: `cache_block_size_dangder`
        }
      }
    },
    rowHeight: {
      type: Number,
      description: 'row_height',
      options: {
        tooltip: {
          show: true,
          content: `row_height_des`
        }
      }
    },
    styleContainerBtnRefresh: {
      type: String,
      description: 'css_style_toolbar',
      default: 'background: white;'
    },

    styleBtnRefresh: {
      type: String,
      description: 'css_style_refresh_btn',
      default: 'font-size: 18px;'
    },

    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },

    initialColumnState: {
      type: Array,
      frozen: true
    },

    filterModel: {
      // type: Object,
      // default: () => { return { } },
      frozen: true
    },
    // из configurator открытие модального окна, чтобы сохранить вид таблицы (columnState)
    openModalWindowByConfigurator: {
      type: Boolean,
      frozen: true
    },

    isPivotMode: {
      type: Boolean,
      default: false,
      frozen: true
    },

    primaryField: {
      type: String,
      default: 'id',
      description: 'primary_field'
    }
  },

  computed: {
    isAutoRefresh () {
      if (Object.keys(this.autoRefresh).length) {
        return !!this.autoRefresh?.isAllow
      }
      return false
    },
    resources () {
      const parameterBuilder = new FilterBuilder(
        this.source.queryParameters || [],
        this.getModel(),
        this.$store,
        EComponentTypes.analyticalTable
      )

      const queryParameters = parameterBuilder.buildAsParameters()

      const filterBuilder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.analyticalTable
      )

      const dataFilters = filterBuilder.buildAsApiQl()

      return {
        queryParameters,
        dataFilters
      }
    },

    // dataFilters () {
    //   const builder = new FilterBuilder(
    //     this.filters,
    //     this.getModel(),
    //     this.$store,
    //     EComponentTypes.analyticalTable
    //   )
    //
    //   return builder.buildAsApiQl()
    // },

    key () {
      return `${this.source.type}_${this.source.entityId}`
    },

    columnState () {
      if (this.isGridReady && this.$refs.grid.columnApi) {
        // console.log('%c%s', 'color: yellow;', 'AnalyticTable columnState computed')

        return this.$refs.grid.columnApi.getColumnState()
      }

      return []
    },

    getFilterModel () {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable getFilterModel')
      if (this.isGridReady) {
        return this.$refs.grid.gridApi.getFilterModel()
      }

      return {}
    }
  },

  watch: {
    resources ({ dataFilters, queryParameters }) {
      if (this.isEditor()) {
        return
      }

      if (this.isFirstInitFilter && this.countFilters === dataFilters.length) {
        this.$refs.grid.load()
        this.dataSourceService.setIsFirstRequest(false)
        this.dataSourceService.setExternalFilters(dataFilters)
        this.isFirstInitFilter = false

        return
      }

      if (
        !this.firstInitWatch ||
        (
          !this.isFirstInitFilter &&
          !deepCompare(this.dataSourceService.getExternalFilters(), dataFilters)
        ) ||
        (
          !this.isFirstInitFilter &&
          !deepCompare(this.dataSourceService.getQueryParameters(), queryParameters)
        )
      ) {
        this.firstInitWatch = true
        this.dataSourceService.setExternalFilters(dataFilters)
        this.dataSourceService.setQueryParameters(queryParameters)
        this.dataSourceService.setIsFirstRequest(false)

        this.$refs.grid.load()
      }
    },

    loading (val) {
      if (val) {
        this.$refs.grid.gridApi?.showLoadingOverlay()
      } else {
        this.$refs.grid.gridApi?.hideOverlay()
      }
    },

    // dataFilters (val) {
    //   if (this.isEditor()) {
    //     return
    //   }
    //   if (this.isFirstInitFilter && this.countFilters === val.length) {
    //     this.$refs.grid.load()
    //     this.dataSourceService.setIsFirstRequest(false)
    //     this.dataSourceService.setExternalFilters(val)
    //     this.isFirstInitFilter = false
    //
    //     return
    //   }
    //
    //   if (!this.firstInitWatch || (!this.isFirstInitFilter && !deepCompare(this.dataSourceService.getExternalFilters(), val))) {
    //     this.firstInitWatch = true
    //     this.dataSourceService.setExternalFilters(val)
    //     this.dataSourceService.setIsFirstRequest(false)
    //
    //     this.$refs.grid.load()
    //   }
    // },

    key () {
      this.isGridReady = false
      this.isComponentReady = false
      this.$emit('change-property', { name: 'initialColumnState', value: [] })
      this.$emit('change-property', { name: 'filterModel', value: {} })
      this.$emit('change-property', { name: 'isPivotMode', value: false })
      this.init()
    },

    dataFilter (value) {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable dataFilter', value)
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'filterModel', value })
      }
    },

    isGridReady (value) {
      if (value && this.initialColumnState) {
        // console.log('%c%s', 'color: yellow;', 'AnalyticTable isGridReady initialColumnState', this.initialColumnState)
        // Внешнее состояние таблицы
        this.$refs.grid.columnApi.applyColumnState({ state: this.initialColumnState, applyOrder: true })
        this.isComponentReady = true
      } else if (value && (this.initialColumnState || []).length === 0) {
        this.isComponentReady = true
      }
      if (this.filterModel && Object.keys(this.filterModel).length) {
        this.$refs.grid.gridApi.setFilterModel(this.filterModel)
      }
    },

    getFilterModel (value) {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable getFilterModel', value)
      if (this.isEditor() && this.isComponentReady) {
        this.$emit('change-property', { name: 'filterModel', value })
      }
    }
  },

  data () {
    return {
      dataSourceService: {},
      isGridReady: false,
      isComponentReady: false,
      filterDebounce: undefined,
      dataFilter: null,
      isSaveFilter: false,
      saveStateColumn: null,
      isFirstInitCmp: true,
      countFilters: 0,
      isFirstInitFilter: true,
      firstInitWatch: false,
      loading: false,
      dataWarehouseCount: 0,
      componentDestroyed: false
    }
  },

  beforeMount () {
    console.log('this.resources', this.resources)

    this.init()
  },
  mounted () {
    setTimeout(() => {
      this.startAutoRefresh()
    }, 10000)
  },
  beforeDestroy () {
    this.componentDestroyed = true
    console.log('beforeDestroy componentDestroyedr', this.componentDestroyed)
  },
  methods: {
    async startAutoRefresh () {
      if (this.autoRefresh?.isAllow && this.autoRefresh.seconds >= 5 && !this.isEditor()) {
        let seconds = this.autoRefresh.seconds * 1000
        this.dataWarehouseCount = await this.getCount()
        try {
          while (!this.componentDestroyed) {
            await this.insertValues()
            await new Promise(resolve => setTimeout(resolve, seconds))
          }
        } catch (error) {
          console.error('Ошибка во время обновления:', error)
        }
      }
    },
    addCSSNewRows (idsNewRows, styleRowsByObj) {
      return (params) => {
        if (params.data && params.data[this.primaryField] && idsNewRows.includes(params.data[this.primaryField])) {
          return styleRowsByObj
        }
      }
    },
    async getCount () {
      let res = 0
      const payload = {
        '*': {
          func: 'count'
        }
      }
      if (this.resources && Object.keys(this.resources.queryParameters).length) {
        payload['query_parameters'] = this.resources.queryParameters
      }
      try {
        await this.$http
          .post(`${this.$config.api}/datawarehouseservice/${this.source.type}/${this.source.entityId}`, payload, { hideNotification: true })
          .then((response) => {
            res = response.data.length ? response.data[0]['count'] : 0
          })
      } catch (error) {
        console.log('ошибка в получении количества записей')
        throw error
      }

      return res
    },
    async insertValues () {
      const gridApi = this.$refs.grid?.gridApi
      if (!gridApi) return
      const gridOptions = this.$refs.grid.gridOptions
      const identifiers = this.primaryField // ключевое поле указанное аналитиком
      const lastPayload = this.getPayload() || {}
      const styleRows = this.autoRefresh.css
      let newDataWithFilter = null
      let dataWarehouseCountNew = 0

      dataWarehouseCountNew = await this.getCount()
      if (dataWarehouseCountNew <= this.dataWarehouseCount) {
        return
      }
      let limitNewRows = dataWarehouseCountNew - this.dataWarehouseCount
      if (limitNewRows === 0) {
        return
      }
      // удалить сортировку в запросе
      const { order, ...restObjectWithoutOrder } = lastPayload
      try {
        newDataWithFilter = (await this.$http.post(`${this.$config.api}/datawarehouseservice/${this.source.type}/${this.source.entityId}`,
          { ...restObjectWithoutOrder, limit: limitNewRows }, { hideNotification: true })).data
      } catch (error) {
        console.warm('timer error get data analyticalTable')
        // clearInterval(this.timerId)
      }
      this.dataWarehouseCount = dataWarehouseCountNew

      if (!Array.isArray(newDataWithFilter)) {
        return
      }
      if (styleRows) {
        const idsNewRows = newDataWithFilter.map(item => item[identifiers])
        const styleArray = styleRows.split(';').map(pair => pair.replace(/\n/g, '').trim())
        const styleObject = {}
        styleArray.forEach(pair => {
          const [key, value] = pair.split(': ')

          styleObject[key] = value
        })
        gridOptions.getRowStyle = this.addCSSNewRows(idsNewRows, styleObject)
      }

      const transaction = {
        addIndex: 0,
        add: newDataWithFilter
      }
      const result = gridApi.applyServerSideTransaction(transaction)
      console.log({ result })
    },
    setRowCount (value) {
      let rowCount = value.api.getDisplayedRowCount()
      this.$set(this.getModel(), this.name, rowCount === 0 ? null : rowCount)
    },
    getPayload () {
      if (!this.dataSourceService) {
        return null
      }

      return this.dataSourceService.getLastPayload()
    },
    /**
     * Вернёт фильтры, которые есть в настройках таблицы
     *
     * @return {Array.<Object>}
     */
    getFilterProps () {
      return this.filters
    },
    /**
     * Вернёт выделенные строки в таблце
     *
     * @return {Array.<Object>}
     */
    getSelectedRows () {
      if (typeof this.$refs.grid.gridApi === 'undefined') {
        return []
      }
      return this.$refs.grid.getSelectedRows()
    },

    getContextMenu () {
      const subMenu = [
        'csvExport',
        'excelExport'
      ]

      if (!this.hideExport) {
        subMenu.push('separator')
        subMenu.push({
          name: 'Экспорт всей таблицы',
          action: () => {
            this.dynamicExport()
          }
        })
      }

      return [
        'copy',
        'copyWithHeaders',
        'paste',
        'separator',
        'chartRange',
        {
          name: 'Экспорт',
          icon: '<span class="el-icon-download"></span>',
          subMenu
        }
      ]
    },

    dynamicExport (item = null) {
      if (!this.columns.length || !this.source.entityId || !this.source.type) {
        return
      }

      console.log('getSelectedRows', this.getSelectedRows())

      const primaryValues = this.getSelectedRows().map(row => row[this.primaryField])

      const { where = null } = this.dataSourceService.getLastPayload()

      const fields = this.columns.map(({ field, columnType, headerName }) => {
        return {
          field,
          type: columnType.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
          name: headerName
        }
      })

      const title = this.$t('registry.message_export.title')
      const message = !item
        ? this.$t('registry.message_export.message')
        : `${this.$t('registry.message_export.task_message')} "${item.task_name}"?`

      this.$confirm(message, title, {
        confirmButtonText: this.$t('main.button.ok'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        const body = {
          fields
        }

        if (!primaryValues.length && where !== null) {
          body.payload = {
            where
          }
        }

        if (primaryValues.length > 0) {
          body.payload = {
            where: {
              and: [
                {
                  in: {
                    [this.primaryField]: primaryValues
                  }
                }
              ]
            }
          }
        }

        this.loading = true

        this
          .$http({
            method: 'post',
            url: !item
              ? `${this.$config.api}/etleditor/export_source/${this.source.type}/${this.source.entityId}`
              : `${this.$config.api}/etleditor/export/${item.task_id}`,
            responseType: 'arraybuffer',
            data: body
          })
          .then(response => {
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            let url = window.URL.createObjectURL(blob)
            window.open(url)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },

    saveFilterModelAndStateTable () {
      // console.log('%c%s', 'color: yellow;', 'AnalyticTable saveFilterModel')
      this.isSaveFilter = !this.isSaveFilter
      let columnState = this.$refs.grid.columnApi.getColumnState()
      let savedPivotMode = this.$refs.grid.columnApi.isPivotMode()
      this.$emit('change-property', { name: 'isPivotMode', value: savedPivotMode })
      this.$emit('change-property', { name: 'initialColumnState', value: columnState })
      this.dataFilter = this.$refs.grid.getFilterModel()
    },

    init () {
      if (!this.source.type || !this.source.entityId) {
        return false
      }
      // console.log('filters', this.filters)
      // console.log('getComponents', this.getComponents())
      // console.log('dataFilters', this.dataFilters)
      // получить компоненты у которых указаны дефолтное значения
      const componentsWithDefaultValue = this.getComponents().filter(item => {
        if (item.initialType === 'a-checkbox') {
          if (Array.isArray(item.properties?.options)) {
            return item.properties.options.some(item => item.default)
          }
        }
        return item.properties?.defaultValue || item.group === 'registry'
      })
      let initFilter = true

      if (this.filters) {
        // подсчитать количество фильтров, которые имеют дефолтное значение
        let asyncFilters = componentsWithDefaultValue.filter(component =>
          this.filters.some(filterItem => filterItem.attribute === component.properties.name)
        ).length || 0

        let sumFilters = this.filters.reduce((acc, filterItem) => {
          if (filterItem.type === 'constant') {
            acc['constant'] += 1
          }
          if (filterItem.type === 'current_user') {
            acc['user'] += 1
          }
          if (filterItem.type === 'registry_field') {
            acc['field'] += 1
          }
          return acc
        }, { constant: 0, user: 0, field: 0, asyncFilters })

        this.countFilters = asyncFilters + sumFilters.constant + sumFilters.user + sumFilters.field
        this.isFirstInitFilter = false
        initFilter = false
      }
      // Если фильтров нет, то при иницилизации компонента грузим данные
      if (!this.countFilters) {
        initFilter = false
        this.isFirstInitFilter = false
        this.firstInitWatch = true
      }

      switch (this.source.type) {
        case 'extended_object':
        case 'query':
          this.dataSourceService = new DataWarehouseSource(
            this.source.entityId,
            this.source.type,
            this.resources.dataFilters,
            this.resources.queryParameters,
            initFilter,
            this
          )
          break
      }
    },

    refreshGrid () {
      this.$refs.grid.load()
      this.$refs.grid.gridApi.deselectAll()
    }
  }
})
</script>

<style scoped>

</style>
